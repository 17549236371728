import React from 'react';
import { 
	Box,
	IconButton,
	Icon,
	Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

const AnswerModal = (props) => {

	return (
		<Modal isOpen={props.isOpen} size='sm' onClose={props.onClose} isCentered>
		<ModalOverlay />
		<ModalContent>
			<ModalCloseButton size='sm' />
			<ModalBody className="answer-modal">
				<h4>The right word is:</h4>
				<h2>{props.word}</h2>
				{props.extra !== null ? <p>{props.extra}</p> : null}
			</ModalBody>
		</ModalContent>
	</Modal>)
}

export default AnswerModal;
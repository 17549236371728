import React from 'react';
import {
	Box,
  Button,
  Divider,
  Icon,
	Grid,
  GridItem,
	Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FiShare } from 'react-icons/fi';
import DigitalClock from '../components/DigitalClock';

export default (props) => {

  const getCssName = (dist, max, rowIdx, currIdx, gameResult) => {
    let css = [];
    if (dist === 0) {
      return ['p0'];
    }
    let pct = 'p' + Math.floor((dist/max)*10)*10;
    let win = rowIdx === currIdx && gameResult ? 'win' : '';
    return [pct, win].join(' ');
  }

  let dist = props.statistic.distribution;

  return (
    <Modal isOpen={props.isOpen} size='lg' onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>STATISTICS</ModalHeader>
        <ModalCloseButton size='sm' />
        <ModalBody>
          <Grid templateColumns='repeat(4, 1fr)' gap={4}>
            <GridItem className="statistics-num-box">
              <Box>{props.statistic.played}</Box>
              <Box>Played</Box>
            </GridItem>
            <GridItem className="statistics-num-box">
              <Box>{props.statistic.winRate}%</Box>
              <Box>Win %</Box>
            </GridItem>
            <GridItem className="statistics-num-box">
              <Box>{props.statistic.currentStreak}</Box>
              <Box>Current Streak</Box>
            </GridItem>
            <GridItem className="statistics-num-box">
              <Box>{props.statistic.maxStreak}</Box>
              <Box>Max Streak</Box>
            </GridItem>
          </Grid>
        </ModalBody>
        <Divider />
        <ModalHeader>GUESS DISTRIBUTION</ModalHeader>
        <div className="distribution-container">
          <div className="attempt-count">
            <div>1</div>
            <div>2</div>
            <div>3</div>
            <div>4</div>
            <div>5</div>
            <div>6</div>
          </div>
          <div className="guess-count">
            <div className={getCssName(dist[1], dist.max, props.rowIndex, 1, props.gameResult.win)}>{dist[1]}</div>
            <div className={getCssName(dist[2], dist.max, props.rowIndex, 2, props.gameResult.win)}>{dist[2]}</div>
            <div className={getCssName(dist[3], dist.max, props.rowIndex, 3, props.gameResult.win)}>{dist[3]}</div>
            <div className={getCssName(dist[4], dist.max, props.rowIndex, 4, props.gameResult.win)}>{dist[4]}</div>
            <div className={getCssName(dist[5], dist.max, props.rowIndex, 5, props.gameResult.win)}>{dist[5]}</div>
            <div className={getCssName(dist[6], dist.max, props.rowIndex, 6, props.gameResult.win)}>{dist[6]}</div>
          </div>
        </div>
        { props.gameResult.done ? ( <Divider /> ) : null }
        { props.gameResult.done ? (
        <ModalFooter>
          {props.expirationTime !== null ? (
          <div className="timer-clock">
            <div className="timer-clock-header">NEXT WORD</div>
            <div className="timer-clock-digital"><DigitalClock target={props.expirationTime} /></div>
          </div>) : null
          }
          <div>
            <Button rightIcon={<Icon as={FiShare} boxSize="1.3em" />} colorScheme='twitter' variant="solid" onClick={props.onCopyClick}>{props.hasCopy ? "Copied" : "Copy"} the result</Button>
          </div>
        </ModalFooter>) : null
        }
      </ModalContent>
    </Modal>
  );
}
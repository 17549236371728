import React from 'react'
import AppRoute from './containers/AppRoute/index'
import { ChakraProvider } from '@chakra-ui/react'
import { Global, css } from '@emotion/react';

const GlobalStyles = css`
	.js-focus-visible: focus:not([data-focus-visible-added]) {
		outline: none;
		box-shadow: none;
	}
`;

export default function App() {

  return (
		<ChakraProvider>
			<Global styles={GlobalStyles} />
			<AppRoute />  
		</ChakraProvider>
  );

}


import React, { useEffect, useState } from 'react';
import {
	Box,
  Button,
  Divider,
  Icon,
	Input,
	Grid,
  GridItem,
	Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
	Stack,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	useToast,
} from '@chakra-ui/react';
import copy from 'copy-to-clipboard';
import axios from 'axios';
import Hosts from 'Hosts';
const host = `${Hosts.API_PROTOCOL}://${Hosts.API_HOST}:${Hosts.API_PORT}`;
const fehost = `${Hosts.FE_PROTOCOL}://${Hosts.FE_HOST}` + (Hosts.FE_PORT === '80' || Hosts.FE_PORT === '443' ? '' : `:${Hosts.FE_PORT}`);

const loadGames = (user_id, games, set_games) => {
	let url = `${host}/wordle/user/${user_id}/games`;
	useEffect(async () => {
		let res = await axios.get(url);
		set_games(res.data);
	},[games.length]);
}

export default (props) => {

	const toastDuration = 5000;
	const toast = useToast();
	const [GAMES, SET_GAMES] = useState([]);
	const [ gameInput, setGameInput ] = useState('');
	const [submitting, setSubmitting] = useState(false);
	loadGames(props.userId, GAMES, SET_GAMES);
	
	const timeDifference = (startTime, endTime) => {
		let begin = new Date(startTime);
		let end = new Date(endTime);
		let diff = Math.floor((end - begin) / 60000);
		if (diff > 60 ) {
			diff = Math.floor((end - begin) / 3600000);
			return `${diff} hour(s)`
		} else	if (diff < 1) { 
			diff = Math.floor((end - begin) / 1000);
			return `${diff} seconds.`
		} else {
			return `${diff} minute(s).`
		}
	}

	const submitNewGame = async () => {
		let url = `${host}/wordle/game`;
		let body = {word: gameInput, user_id: props.userId};
		if (gameInput.length !== 5) {
			toast({
				title: 'Not enough letters.',
				status: 'error',
				duration: toastDuration,
				position: 'top',
				isClosable: true,
			});
		} else {
			setSubmitting(true);
			let response = await axios.post(url, body);
			if ('validated' in response.data) {
				toast({
					title: 'Not in a word list',
					status: 'error',
					duration: toastDuration,
					position: 'top',
					isClosable: true,
				});
			} else {
				let newrow = {id: response.data.id, word: gameInput.toLowerCase(), expiration: response.data.expiration};
				SET_GAMES([ ...GAMES, newrow]);
				setGameInput('');
			}
			setTimeout(() => {
				setSubmitting(false);
			}, 500);
			console.log(response);
		}
	}

	const copyHandler = (e) => {
		let id = e.target.value;
		let url = `${fehost}/invites/${id}`;
		let result = copy(url, { format: 'text/plain' });
		if (result) {
			toast({
				title: 'URL copied!',
				status: 'success',
				duration: toastDuration,
				position: 'top',
				isClosable: true,
			});
		}
	}

	return (
		<Modal isOpen={props.isOpen} size='lg' onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>YOUR OWN GAMES</ModalHeader>
        <ModalCloseButton size='sm' />
        <ModalBody>
          {GAMES.length > 0 ? (
						<Table variant='simple' size='sm' className='games-table'>
							<Thead>
								<Tr>
									<Th>ID</Th>
									<Th>Word</Th>
									<Th>Expires in</Th>
								</Tr>
							</Thead>
							<Tbody>
							{GAMES.map((row, idx)=>{
									return (<Tr key={`tr-${idx}`}>
										<Td key={`id-${row.id}`}><Button size={['xs','sm']} onClick={copyHandler} value={row.id}>{row.id}</Button></Td>
										<Td key={`word-${row.word}`}>{row.word}</Td>
										<Td key={`exp-${row.expiration}`}>{timeDifference(new Date(), new Date(row.expiration))}</Td>
									</Tr>)
								})}
							</Tbody>
						</Table>
					) : null}
        </ModalBody>
        <ModalHeader>CREATE YOUR OWN GAME</ModalHeader>
				<ModalBody>
					<Stack direction='row' spacing={2}>
						<Input onChange={(e) => {
							if (e.target.value.length <= 5) {
								setGameInput(e.target.value.toLowerCase());
							}
						}} htmlsize={5} width='auto' variant="outline" placeholder="Enter a Word" value={gameInput} />
						{ submitting ? <Button isLoading loadingText='Creating' onClick={submitNewGame}>Create</Button> : <Button onClick={submitNewGame}>Create</Button>
						}
					</Stack>
					<Box className='own-game-desc'>
						You can click on the ID in the table to copy the url of your game and send it to your friends.
					</Box>
				</ModalBody>
      </ModalContent>
		</Modal>
	);
}
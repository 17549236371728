import React, { useState, useEffect } from 'react';

const DigitalClock = (props) => {
	
	let timer;
	const _sec = 1000;
	const _min = _sec * 60;
	const _hour = _min * 60;
	const _day = _hour * 24;
	const [time, setTime] = useState('');
	
	const showRemaining = () => {
		let now = new Date();
		let dist = props.target - now;
	
		if (dist < 0) {
			clearInterval(timer);
		}
	
		let hour = '' + Math.floor((dist % _day) / _hour);
		hour = hour.length === 1 ? `0${hour}` : hour;
		let min = '' + Math.floor((dist % _hour) / _min);
		min = min.length === 1 ? `0${min}` : min;
		let sec = '' + Math.floor((dist % _min) / _sec);
		sec = sec.length === 1 ? `0${sec}` : sec;
		
		setTime(`${hour}:${min}:${sec}`);
	};
	
	useEffect(()=>{
		if (props.target !== null) {
			showRemaining();
			timer = setInterval(showRemaining, 1000);
		}
	}, [1]);

	return(<span>{time}</span>)
}

export default DigitalClock;
import React from 'react';
import {
	Box,
  Button,
  Divider,
  Icon,
	Grid,
  GridItem,
  List,
  HStack,
  ListItem,
  UnorderedList,
	Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { MdOutlineCreate } from 'react-icons/md';
import Hosts from 'Hosts';

const HOST = `${Hosts.FE_PROTOCOL}://${Hosts.FE_HOST}` + (Hosts.FE_PORT === '443' || Hosts.FE_PORT === '80' ? '' : `:${Hosts.FE_PORT}`);

export default (props) => {
	return (
		<Modal scrollBehavior='inside' isOpen={props.isOpen} size='lg' onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="info-modal-container">
        <ModalHeader>GAME INFORMATION</ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize='sm'>
          <h3>GAME MODE</h3>
          <div className="about-paragrah">
            There are total 3 game modes (You can click on BTS or Regular to play it now!):
          </div>
          <UnorderedList>
            <ListItem><a className='link-in-about' href={`${HOST}/`}>BTS</a></ListItem>
            <ListItem><a className='link-in-about' href={`${HOST}/regular`}>Regular</a></ListItem>
            <ListItem>Custom Game (Need an invite link if you want to play!)</ListItem>
          </UnorderedList>
          <Divider />
          <h3>HOW TO PLAY</h3>
          <div className="about-paragrah">
            <p>Guess the WORDLE in 6 tries.</p>
            <p>Each guess must be a valid 5 letter word. Hit or click on the enter button to submit.</p>
            <p>After each guess, the color of the tiles will change to show how close your guess was to the word.</p>
            <p>Examples</p>
          </div>
          <HStack className='example game-tile-row' width="500" columns={5} spacing={1} justifyContent="center">
              <Box className='game-tile game-tile-ready found-in-pos'>S</Box>
              <Box className='game-tile game-tile-ready not-found'>U</Box>
              <Box className='game-tile game-tile-ready not-found'>G</Box>
              <Box className='game-tile game-tile-ready not-found'>A</Box>
              <Box className='game-tile game-tile-ready not-found'>R</Box>
          </HStack>
          <p>The letter <strong>S</strong> is in the word and in the correct spot.</p>
          <HStack className='example game-tile-row' width="500" columns={5} spacing={1} justifyContent="center">
              <Box className='game-tile game-tile-ready not-found'>J</Box>
              <Box className='game-tile game-tile-ready found'>I</Box>
              <Box className='game-tile game-tile-ready not-found'>M</Box>
              <Box className='game-tile game-tile-ready not-found'>I</Box>
              <Box className='game-tile game-tile-ready not-found'>N</Box>
          </HStack>
          <p>The letter <strong>I</strong> is in the word but in the wrong spot.</p>
          <HStack className='example game-tile-row' width="500" columns={5} spacing={1} justifyContent="center">
              <Box className='game-tile game-tile-ready'>A</Box>
              <Box className='game-tile game-tile-ready'>R</Box>
              <Box className='game-tile game-tile-ready not-found'>M</Box>
              <Box className='game-tile game-tile-ready'>Y</Box>
              <Box className='game-tile game-tile-ready'>S</Box>
          </HStack>
          <p>The letter <strong>M</strong> is not in the word in any spot.</p>
          <Divider />
          <p>You can play only one game in each day for each game mode (BTS and Regular). However, you can play as many games as you like if you are invited to a custom game.</p>
          <Divider />
          <h3>CUSTOM GAME</h3>
          <div className="about-paragrah">
            <p>It is very easy to create your own game and share it with your friends!</p>
            <p>Just follow these steps:</p>
            <UnorderedList>
              <ListItem>Click on <Icon as={MdOutlineCreate} boxSize='1.3em' /> in the top header.</ListItem>
              <ListItem>Enter your word in the text box. (You can create a game with only valid and 5 character word or the system will reject your request.)</ListItem>
              <ListItem>Click on <strong>Create</strong></ListItem>
              <ListItem>Click on the ID that matches with your word that you just entered.</ListItem>
              <ListItem>You can simply Ctrl + P to share in twitter, facebook, messenger, and so on.</ListItem>
            </UnorderedList>
          </div>
          <Divider />
          <h3>THEMES</h3>
          <div className="about-paragraph last-paragraph">
            <RadioGroup onClick={props.onUpdateTheme} value={props.theme}>
              <Stack direction="column">
                <Radio value="Plain">Plain</Radio>
                <Radio value="Theme 1">Theme 1</Radio>
                <Radio value="Theme 2">Theme 2</Radio>
              </Stack>
            </RadioGroup>
          </div>
          <Divider />
          <h3>ABOUT THIS GAME</h3>
          <div className="about-paragraph last-paragraph">
          The game system is maintained by <a className='link-in-about' href="https://twitter.com/BTSTrends_Org">BTSTrends_Org</a>. Please report any bugs to the twitter handle. 
          </div>
        </ModalBody>
      </ModalContent>
		</Modal>
	);
}
import React from 'react';

const GameTiles = (props) => {

	const axisX = 5;
	const axisY = 6;

	const generateGameTiles = (tiles, shakyRow) => {
		let elm = [];
		let rcount=1, ccount=1;
		for (let r=0;r<axisY;r++) {
			let row = tiles[r];
			let colArr = [];
			for (let c=0;c<axisX;c++) {
				let col = row[c] || null;
				let cssName = ['game-tile'];
				if (col !== null) {
					switch(col.result) {
						case 'found':
							cssName.push('found');
							break;
						case 'exact':
							cssName.push('found-in-pos');
							break;
						case 'notfound':
							cssName.push('not-found');
							break;
						default:
							cssName.push('entered');
					}
				}
	
				colArr.push((
						<div className={cssName.join(' ')} key={`box-${rcount}-${ccount}`}>
							{col !== null ? col.letter.toUpperCase() : null}
						</div>
					));
				ccount++;
			}
			let rowCls = ['game-tile-row'];
			if (r === shakyRow) {
				rowCls.push('shaky-row');
			}
			elm.push(<div className={rowCls.join(' ')} key={`row-${rcount}`}>{colArr}</div>);
			rcount++;
		}
		return (<div className='container-tile-rows' spacing={3}>{elm}</div>);
	}
	return generateGameTiles(props.tileData, props.shakyRow);
}

export default GameTiles;
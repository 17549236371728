import React, { useEffect, useState } from 'react';
import { 
	Box,
	Center,
	Container,
	Flex,
} from '@chakra-ui/react';
import { 
	useToast 
} from '@chakra-ui/react'
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Keyboard from './Keyboard'
import StatsModal from './StatsModal'
import AboutModal from './AboutModal'
import CustomGameModal from './CustomGameModal'
import GameTiles from '../components/GameTiles'
import GameHeader from '../components/GameHeader'
import AnswerModal from '../components/AnswerModal'
import Hosts from 'Hosts';
import uuidv4 from 'uuid-browser/v4';
import Cache from '../utils/Cache';

const host = `${Hosts.API_PROTOCOL}://${Hosts.API_HOST}:${Hosts.API_PORT}`;
const FEHOST = `${Hosts.FE_PROTOCOL}://${Hosts.FE_HOST}` + (Hosts.FE_PORT === '443' || Hosts.FE_PORT === '80' ? '' : `:${Hosts.FE_PORT}`);

const CustomGame = (props) => {
	const { gameid } = useParams();
	const GAMEID = gameid === undefined ? '' : gameid;
	const GAMEMODE = 'CUSTOM';
	const UUID = Cache.getLocalCache('UUID', uuidv4(), 'string');
	const createUUID = () => {
		useEffect(async()=>{
			let url = `${host}/wordle/user`;
			await axios.post(url, { 
				id: UUID,
			});
			//let json = response.data;
		},[UUID])
	}
	createUUID();

	const resetTodayAndReturn = () => {
		let customGames = Cache.getLocalCache('CUSTOM_GAMES', {}, 'json');
		if (!(GAMEID in customGames)) {
			localStorage.removeItem('CUSTOM_NOT_LIST');
			localStorage.removeItem('CUSTOM_IN_LIST');
			localStorage.removeItem('CUSTOM_COR_LIST');
			localStorage.removeItem('CUSTOM_rowIndex');
			localStorage.removeItem('CUSTOM_tileData');
			localStorage.removeItem('CUSTOM_shakyRow');
			localStorage.removeItem('CUSTOM_todayGameResult');
			localStorage.removeItem('CUSTOM_GAME_BEGIN');
			localStorage.removeItem('CUSTOM_GAME_FINISHED');
		}
	}
	resetTodayAndReturn();

	useEffect(() => {
		// check if a custom game has been played before
		let customGames = Cache.getLocalCache('CUSTOM_GAMES', {}, 'json');
		if (GAMEID in customGames) {
			if (customGames[GAMEID] > 1) {
				toast({
					title: 'You already played and ' + (customGames[GAMEID] === 2 ? 'won the game.' : 'lost the game'),
					status: (customGames[GAMEID] === 2 ? 'success' : 'error'),
					duration: 5000,
					position: 'top',
					isClosable: true,
				});
			}
			// game exists already so likely it was played already
		}
	}, [1]);

	const toast = useToast();
	const [ rowIndex, setRowIndex ] = useState(Cache.getLocalCache('CUSTOM_rowIndex', 0, 'num'));
	const [ tileData, setTileData ] = useState(Cache.getLocalCache('CUSTOM_tileData', [[],[],[],[],[],[]], 'json'));
	const [ todayGameResult, setTodayGameResult ] = useState(Cache.getLocalCache('CUSTOM_todayGameResult', { win: false, done: false, }, 'json'));
	const [ shakyRow, setShakyRow ] = useState(Cache.getLocalCache('CUSTOM_shakyRow', -1, 'num'));
	const [ isStatsModalOpen, setIsStatsModalOpen ] = useState(false);
	const [ isAboutModalOpen, setIsAboutModalOpen ] = useState(false);
	const [ cGameModalOpen, setCGameModalOpen ] = useState(false);
	const [ copyContent, setCopyContent ] = useState('');
	const [ hasCopy, setHasCopy ] = useState(false);
	const [ answerModal, setAnswerModal ] = useState({open: false, word: '', extra: null});
	const [ theme, setTheme ] = useState(Cache.getLocalCache('THEME_ID', 'Plain', 'string'));
	
	const toastDuration = 1500;

	const NOT_LIST = Cache.getLocalCache('CUSTOM_NOT_LIST', {}, 'json');
	const IN_LIST = Cache.getLocalCache('CUSTOM_IN_LIST', {}, 'json');
	const COR_LIST = Cache.getLocalCache('CUSTOM_COR_LIST', {}, 'json');
	
	const initStatistics = {
		played: 0, winRate: 0, currentStreak:0, maxStreak: 0, win: 0,
		distribution: {
			'1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, max: 0,
		},
	}
	const axisX = 5;
	const axisY = 6;
	const STATISTIC = Cache.getLocalCache('CUSTOM_STATISTIC', initStatistics, 'json');
	const GAME_BEGUN = Cache.getLocalCache('CUSTOM_GAME_BEGIN', null, 'date');
	const GAME_FINISHED = Cache.getLocalCache('CUSTOM_GAME_FINISHED', null, 'date');
	
	const isGameDone = (row, rowIndex) => {
		let count = 0;
		for (let o of row) {
			if (o.result === 'exact') {
				count++;
			}
		}
		return { win: count === 5, done: count === 5 ? true : (rowIndex >= axisY) };
	}
	
	const tagRow = (row) => {
	
		for (let o of row) {
			switch(o.result) {
				case 'exact':
					COR_LIST[o.letter.toLowerCase()] = 1;
					Cache.setLocalCache('CUSTOM_COR_LIST', COR_LIST, 'json');
					break;
				case 'found':
					IN_LIST[o.letter.toLowerCase()] = 1;
					Cache.setLocalCache('CUSTOM_IN_LIST', IN_LIST, 'json');
					break;
				case 'notfound':
					NOT_LIST[o.letter.toLowerCase()] = 1;
					Cache.setLocalCache('CUSTOM_NOT_LIST', NOT_LIST, 'json');
			}
		}
	
	}
	
	const clickHandler = (e) => {
		let key = e.target.innerText;
		letterHandler(key);
	}

	const clearShakyRow = () => {
		setTimeout(()=>{
			Cache.setLocalCache('CUSTOM_shakyRow', -1, 'num');
			setShakyRow(-1);
		}, 500);
	}

	const enterHandler = async () => {
		let newData = _.cloneDeep(tileData);
		let count = newData[rowIndex].length;
		if (count < 5) {
			toast({
				title: 'Not enough letters.',
				status: 'warning',
				duration: toastDuration,
				position: 'top',
				isClosable: true,
			});
			Cache.setLocalCache('CUSTOM_shakyRow', rowIndex, 'num');
			setShakyRow(rowIndex);
			clearShakyRow();
			return;
		}

		if (count === 5) {
			let url = `${host}/wordle/validate`;
			let today = new Date();
			let month = (today.getMonth() + 1) + '';
			month = month.length === 1 ? `0${month}` : month;
			let date = today.getDate() + '';
			date = date.length === 1 ? `0${date}` : date;
			let payload = {
				word: newData[rowIndex].map(e => e.letter).join(''),
				today: today.getFullYear() + '-' + month + '-' + date, 
				mode: GAMEMODE,
				game_id: GAMEID,
				current_index: rowIndex,
			}
			let response = await axios.post(url, payload);
			let json = response.data;
			let goal = '';
			if (json.length > 5) {
				goal = json.splice(5)[0].target_word;
			}
			// check if the game is expired or not
			if ('expired' in json) {
				// the game has been expired already.
				toast({
					title: 'Sorry. The game has been expired. You cannot play an expired game.',
					status: 'error',
					duration: 5000,
					position: 'top',
					isClosable: true,
				});
				return;
			}
			// console.log(response);
			if ('validated' in json) {
				toast({
					title: 'Not in a word list',
					status: 'error',
					duration: toastDuration,
					position: 'top',
					isClosable: true,
				});
				Cache.setLocalCache('CUSTOM_shakyRow', rowIndex, 'num');
				setShakyRow(rowIndex);
				clearShakyRow();
			} else {
				let nextRowIndex = rowIndex + 1;
				let result = isGameDone(json, nextRowIndex);
				tagRow(json);
				newData[rowIndex] = json;
				Cache.setLocalCache('CUSTOM_tileData', newData, 'json');
				Cache.setLocalCache('CUSTOM_todayGameResult', result, 'json');
				Cache.setLocalCache('CUSTOM_rowIndex', nextRowIndex, 'num')
				setTileData(newData);
				setTodayGameResult(result);
				setRowIndex(nextRowIndex);
				if (result.win && result.done) {
					STATISTIC.played++;
					STATISTIC.win++;
					STATISTIC.currentStreak++;
					STATISTIC.maxStreak = STATISTIC.maxStreak < STATISTIC.currentStreak ? STATISTIC.currentStreak : STATISTIC.maxStreak;
					STATISTIC.distribution[nextRowIndex]++;
					let distMax = 0;
					for (let k in STATISTIC.distribution) {
						if (k !== 'max' && STATISTIC.distribution[k] > distMax) {
							distMax = STATISTIC.distribution[k];
						}
					}
					STATISTIC.distribution.max = distMax;
					STATISTIC.winRate = (STATISTIC.win / STATISTIC.played * 100).toFixed();
					Cache.setLocalCache('CUSTOM_STATISTIC', STATISTIC, 'json');
					Cache.setLocalCache('CUSTOM_GAME_FINISHED', new Date(), 'date');
					toast({
						title: 'Success!',
						status: 'success',
						duration: toastDuration,
						position: 'top',
						isClosable: true,
					});
					clearShakyRow();
					let customGames = Cache.getLocalCache('CUSTOM_GAMES', customGames, 'json');
					customGames[GAMEID] = 2;
					Cache.setLocalCache('CUSTOM_GAMES', customGames, 'json');
				} else if ( result.done && !result.win ) {
					STATISTIC.played++;
					STATISTIC.winRate = (STATISTIC.win / STATISTIC.played * 100).toFixed();
					STATISTIC.currentStreak = 0;
					Cache.setLocalCache('CUSTOM_STATISTIC', STATISTIC, 'json');
					Cache.setLocalCache('CUSTOM_GAME_FINISHED', new Date(), 'date');
					setAnswerModal({
						word: goal.toUpperCase(),
						open: true,
						extra: "Your friend must have tricked you. Now you challenge your friend by creating your own game!",
					});
					let customGames = Cache.getLocalCache('CUSTOM_GAMES', customGames, 'json');
					customGames[GAMEID] = 3;
					Cache.setLocalCache('CUSTOM_GAMES', customGames, 'json');
				}
			}
		}
	}

	const backspaceHandler = () => {
		let newData = _.cloneDeep(tileData);
		let currentLen = newData[rowIndex].length;
		if (currentLen !== 0) {
			newData[rowIndex].pop();
		}
		Cache.setLocalCache('CUSTOM_tileData', newData, 'json');
		setTileData(newData);
	}

	const letterHandler = (input) => {
		if (/[a-zA-Z]/.test(input)) {
			if (tileData[0].length === 0 && Cache.getLocalCache('CUSTOM_GAME_BEGIN', null, 'date') === null) {
				Cache.setLocalCache('CUSTOM_GAME_BEGIN', new Date(), 'date');
				let customGames = Cache.getLocalCache('CUSTOM_GAMES', customGames, 'json');
				customGames[GAMEID] = 1;
				Cache.setLocalCache('CUSTOM_GAMES', customGames, 'json');
			}
			let newData = _.cloneDeep(tileData);
			let currentLen = newData[rowIndex].length;
			if (currentLen >= axisX) return;
			newData[rowIndex].push({letter: input, result: ''});
			Cache.setLocalCache('CUSTOM_tileData', newData, 'json');
			setTileData(newData);
		}
	}

	const keyHandler = (evt) => {
		if (todayGameResult.done === false) {
			if (evt.keyCode === 13) {
				// enter
				enterHandler();
			}
			if (evt.keyCode === 8) {
				// backspace
				backspaceHandler();
			}
			let input = String.fromCharCode(evt.keyCode);
			letterHandler(input);
		}
	}

	const timeDifference = (startTime, endTime) => {
		let begin = new Date(startTime);
		let end = new Date(endTime);
		let diff = Math.floor((end - begin) / 60000);
		if (diff > 60 ) {
			diff = Math.floor((end - begin) / 3600000);
			return `I played for ${diff} hour(s)`
		} else	if (diff < 1) { 
			diff = Math.floor((end - begin) / 1000);
			return `I played for ${diff} seconds.`
		} else {
			return `I played for ${diff} minute(s).`
		}
	}

	const copyHandler = () => {
		let content = `Custom Wordle -`;
		if (todayGameResult.win) {
			content += ` ${rowIndex}/6\n`;
		}
		content += timeDifference(GAME_BEGUN, GAME_FINISHED) + '\n';
		content += '\n'
		for (let r of tileData) {
			for (let c of r) {
				if (c.result === 'notfound') {
					content += '⬜';
				}
				if (c.result === 'found') {
					content += '🟫';
				}
				if (c.result === 'exact') {
					content += '🟪';
				}
			}
			if (r.length > 0) {
				content += '\n';
			}
		}
		content += `\n(If you want to challenge the same game,\nclick on ${FEHOST}/invites/${GAMEID})\n`;

		let result = copy(content, { format: 'text/plain' });
		setCopyContent(content);
		if (result) {
			setHasCopy(true);
			setTimeout(()=>{ setHasCopy(false)}, 2000);
		}

	}

	const updateTheme = (e) => {
		let input = e.target.innerText;
		let body = document.getElementsByTagName('body')[0];
		if (input !== "") {
			body.classList.remove('theme1');
			body.classList.remove('theme2');
		}
		switch(input) {
			case 'Plain':
				setTheme(input);
				Cache.setLocalCache('THEME_ID', input, 'string');
				break;
			case 'Theme 1':
				body.classList.add('theme1');
				setTheme(input);
				Cache.setLocalCache('THEME_ID', input, 'string');
				break;
			case 'Theme 2':
				body.classList.add('theme2');
				setTheme(input);
				Cache.setLocalCache('THEME_ID', input, 'string');
				break;
		}
	}

	useEffect(()=>{
		let id = Cache.getLocalCache('THEME_ID', 'Plain', 'string')
		let obj = { target: { innerText: id } }
		updateTheme(obj);
	},[1]);

	useEffect(()=>{
		if (todayGameResult.done !== false) {
			document.addEventListener('keydown', keyHandler);
		}
		return (()=>{
			if (todayGameResult.done !== false) {
				document.removeEventListener('keydown', keyHandler);
			}
		})
	}, [tileData, rowIndex, todayGameResult, shakyRow]);

	return (
		<div onKeyDown={keyHandler} tabIndex={0}>
			<Container maxW='container.sm' centerContent>
				<Flex className="flexbox" flexDirection="column" justifyContent="space-between">
					<GameHeader 
						fontSize="sm"
						title="GUESS YOUR FRIEND'S WORD"
						statsModalHandler={() => {
							setIsStatsModalOpen(true);
						}}
						cgameModalHandler={()=>{
							setCGameModalOpen(true);
						}}
						aboutModalHandler={()=>{
							setIsAboutModalOpen(true)
						}}
					/>
					<GameTiles tileData={tileData} shakyRow={shakyRow} />
					<Box className="spacer">s</Box>
					<Keyboard 
						notList={NOT_LIST} 
						inList={IN_LIST} 
						exactList={COR_LIST} 
						keyClickHandler={clickHandler}
						enterClickHandler={enterHandler}
						backspaceClickHandler={backspaceHandler}
				  />
				</Flex>
			</Container>
			<StatsModal
				isOpen={isStatsModalOpen}
				onClose={()=>{
					setIsStatsModalOpen(false);
				}}
				statistic={STATISTIC}
				gameResult={todayGameResult}
				onCopyClick={copyHandler}
				hasCopy={hasCopy}
				rowIndex={rowIndex}
				expirationTime={null}
			 />
			<AboutModal 
				isOpen={isAboutModalOpen}
				onClose={() => {
					setIsAboutModalOpen(false);
				}}
				onUpdateTheme={updateTheme}
				theme={theme}
			/>
			<CustomGameModal
				userId={UUID}
			  isOpen={cGameModalOpen}
			  onClose={()=>{
					setCGameModalOpen(false);
			  }} 
			/>
			<AnswerModal
				isOpen={answerModal.open}
				onClose={
					() => {
						setAnswerModal({...setAnswerModal, open: false});
					}
				}
				word={answerModal.word}
				extra={answerModal.extra}
			/>
		</div>
	);

}

export default CustomGame;
import React from 'react';
import { 
	Box,
	IconButton,
	Icon,
} from '@chakra-ui/react';
import { ImStatsBars } from 'react-icons/im';
import { AiOutlineInfo } from 'react-icons/ai';
import { MdOutlineCreate } from 'react-icons/md';

const GameHeader = (props) => {

	let clsName = [ "game-header-title" ];
	if (props.fontSize === "sm") {
		clsName.push("header-title-sm");
	} else {
		clsName.push("header-title-md");
	}

	return (
	<Box className="game-header">
		<div className={clsName.join(' ')}>{props.title}</div>
		<div className="game-options">
			<IconButton className='icon-spacing' onClick={props.statsModalHandler} variant='solid' size="xs" colorScheme="gray" icon={<Icon as={ImStatsBars} boxSize="1.3em" />} />
			<IconButton className='icon-spacing' onClick={props.cgameModalHandler} variant='solid' size='xs' colorScheme='gray' icon={<Icon as={MdOutlineCreate} boxSize='1.3em' />} />
			<IconButton onClick={props.aboutModalHandler} variant='solid' size='xs' colorScheme="gray" icon={<Icon as={AiOutlineInfo} boxSize="1.3em" />} />
		</div>
	</Box>)
}

export default GameHeader;
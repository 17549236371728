

const setLocalCache = (key, value, dataType) => {

	let newData;
	switch(dataType) {
		case "json":
			newData = JSON.stringify(value);
			break;
		case "date":
			if (value === null) {
				newData = null
			} else {
				newData = value.toISOString();
			}
			break;
		case 'num':
			if (value === null) {
				newData = -1;
			} else {
				newData = parseInt(value);
			}
		default:
			newData = value;
	}
	localStorage.setItem(key, newData);
};

const getLocalCache = (key, initial, dataType) => {

	if (localStorage.getItem(key) === null) {
		setLocalCache(key, initial, dataType)
	}
	let data = localStorage.getItem(key);
	if (data === 'null') return null;
	switch(dataType) {
		case "json":
			data = JSON.parse(data);
			break;
		case "num":
			data = parseInt(data);
			break;
		case "date":
			data = new Date(data);
			break;
		case "bool":
			data = data.toLowerCase() === 'true'
			break;
	}
	return data;
};

export default {
	setLocalCache: setLocalCache,
	getLocalCache: getLocalCache,
};
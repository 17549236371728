import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "../style/main.scss";
import 'focus-visible/dist/focus-visible';


ReactDOM.render((
		<App />
	), 
	document.getElementById("root"));
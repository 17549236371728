import React from 'react';
import { BrowserRouter, Routes, Route,} from 'react-router-dom';
import RegularGame from '../RegularGame'
import CustomGame from '../CustomGame'
import BTSGame from '../BTSGame'

const AppRoute = (props) => {
	return (
		<BrowserRouter>
			<Routes>
				<Route exact path="/" element={<BTSGame />} />
				<Route exact path="/regular" element={<RegularGame />} />
				<Route path="/invites/:gameid" element={<CustomGame />} />
			</Routes>
		</BrowserRouter>
	)
}

export default AppRoute;
import React, { useEffect, useState } from 'react';

export default (props) => {

	let elements = [];
	let svg = (<svg key="svg" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20">
	<path key="path" fill="var(--color-tone-1)" d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"></path>
</svg>);
	let keyboard = [
		['Q','W','E','R','T','Y','U','I','O','P'],
		['A','S','D','F','G','H','J','K','L'],
		['ENTER', 'Z','X','C','V','B','N','M', svg]
	]
	
	for (let row of keyboard) {
		let boxes = [];
		let count = row.length;
		for (let c of row) {
			let clsName = ['keyboard-key'];
			let char = typeof c === 'string' ? c.toLowerCase() : '-';
			let onclick = props.keyClickHandler;
			if (char in props.notList) {
				clsName.push('not-found-in-keyboard');
			}
			if (char in props.inList) {
				clsName.push('found-in-keyboard');
			}
			if (char in props.exactList) {
				clsName.push('exact-in-keyboard');
			}
			if (c === 'ENTER' || c === svg) {
				clsName.push('long-key');
			}
			if (c === svg) {
				onclick = props.backspaceClickHandler
			}
			if (c === 'ENTER') {
				onclick = props.enterClickHandler
			}
			boxes.push(<div onClick={onclick} key={c} className={clsName.join(' ')}>{c}</div>);
		}
		elements.push(<div className='keyboard-row' key={row.shift()}>{boxes}</div>);
	}

	let finalElm = (<div className='container-keyboard-rows'>{elements}</div>);
	return finalElm;	
	
}